.Page {
  padding: 40px;
}

.Page-header {
  margin-bottom: 14px;
}

.Page-heading-accent-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.Page-heading-accent {
  width: 48px;
  height: 8px;
  background-color: var(--accent);
  border-radius: 8px;
  margin-bottom: 40px;
}

.Page-children-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 599px) {
  .Page {
    padding: 40px 20px;
  }
}