#Footer {
  background-color: var(--background-alt);
  width: 100%;
  /* height: 300px; */
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
