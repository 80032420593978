#Education {
  background-color: var(--background-alt);
}

.Education-description {
  width: 60%;
  max-width: 600px;
  padding: 20px 0;
}

.Education-buttons > * {
  margin: 0 10px 40px !important;
}

.Education-IBE-highlight {
  color: var(--accent);
  font-weight: bold;
}

.Education-coursework-subtitle {
  margin: 0;
}

.Education-skills-container {
  width: 400px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.Education-skills-container > * {
  padding: 0 10px;
}

.Education-skills-chip-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 200px;
  flex-wrap: wrap;
}

.Education-skills-chip-wrapper > * {
  margin: 4px;
  line-height: 1;
}

.skill-1 {
  background-color: var(--accent) !important;
  color: var(--text-primary) !important;
}

.skill-2 {
  background-color: var(--accent-1) !important;
  color: var(--text-primary) !important;
}

.skill-3 {
  background-color: var(--skill-3) !important;
  color: var(--text-primary) !important;
}

.Education-mini-list {
  max-width: 400px;
}

.Education-lead-ach-item {
  margin: 0 20px;
}

.Education-lead-ach-row {
  display: flex;
  width: 76%;
  max-width: 1000px;
  justify-content: space-between;
  margin: 40px 0;
}

@media (max-width: 1199px) {
  .Education-lead-ach-row {
    width: 86%;
  }
}

@media (max-width: 899px) {
  .Education-description {
    width: 70%;
  }

  .Education-coursework-subtitle {
    display: none;
  }

  .Education-lead-ach-row {
    width: 90%;
    max-width: 400px;
    flex-direction: column;
  }

  .Education-lead {
    margin-bottom: 20px;
  }
}

@media (max-width: 599px) {
  .Education-description {
    width: 90%;
  }

  .Education-skills-container {
    width: auto;
    flex-direction: column;
  }

  .Education-skills-chip-wrapper {
    width: 80vw;
    max-width: 360px;
    justify-content: center;
    padding: 10px 0;
  }

  .Education-lead-ach-row {
    width: 100%;
    max-width: none;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .Education-lead-ach-item {
    margin: 10px;
    max-width: 360px;
  }
}