.TopNavBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: height var(--transition-dur);
  z-index: 1000;
  background-color: var(--background);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 12px var(--shadow-color);
  padding: 0 40px;
}

.TopNavBar.visible {
  height: var(--top-nav-height);
}

.TopNavBar-links {
  display: flex;
}

.TopNavBar-links > .nav-link {
  padding: 0 16px;
}

.TopNavBar-links > .Links-icons {
  margin-left: 20px;
}

.menu-icon {
  color: var(--text-primary) !important;
}

.MuiListItemText-primary {
  font-size: 1.4rem !important;
  padding: 0.6rem 1.4rem;
}

@media (max-width: 899px) {
  .TopNavBar {
    height: var(--top-nav-height);
  }
}