.MiniListItem {
  display: flex;
  margin: 16px 0;
}

.MiniListItem-text {
  padding: 0;
  margin-left: 10px;
  text-align: left;
}

.MiniListItem-text > * {
  padding: 0;
  margin: 0;
}

.MiniListItem-date {
  font-style: italic;
  color: var(--text-secondary);
}