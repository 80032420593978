:root {
  --background: white;
  --background-alt: #F4F4F4;
  --text-primary: #333333;
  --text-secondary: #777777;
  --text-secondary-light: #AAAAAA;
  --accent: #71ADD8;
  --accent-1: #AED3EE;
  --accent-2: #DDECF8;
  --skill-3: #DDDDDD;
  --home-bkg-overlay: rgba(255, 255, 255, 0.4);
  --shadow-color: rgba(136, 136, 136, 0.25);
  --transition-dur: 0.4s;
  --top-nav-height: 80px;
}
[data-theme='dark'] {
  --background: #333333;
  --background-alt: #444444;
  --text-primary: white;
  --text-secondary: #BBBBBB;
  --accent: #4C87B1;
  --accent-1: #396585;
  --accent-2: #26445A;
  --skill-3: #666666;
  --home-bkg-overlay: rgba(0, 0, 0, 0.4);
  --shadow-color: rgba(100, 100, 100, 0.8);
}

body {
  margin: 0;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, p {
  font-family: "Source Serif 4", serif;
  color: var(--text-primary);
  font-weight: 400;
  line-height: 1.5;
}

p {
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

h1 {
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 400;
  letter-spacing: 2.8px;
}

h2 {
  font-size: 2.2rem;/* 2rem */
  font-family: "Source Serif 4", serif;
}

h3 {
  font-size: 1.55rem;/* 1.35rem; */
  font-family: "Source Serif 4", serif;
}

h4, h5 {
  font-size: 1.22rem;/* 1.15rem; */
}

h6 {
  font-size: 1.15rem;/* 1.1rem; */
}

a {
  color: var(--accent);
  text-decoration: none;
  transition: var(--transition-dur);
}

a:hover {
  cursor: pointer;
  color: var(--accent-1);
}

.quote {
  padding-left: 20px !important;
  padding-right: 20px !important;
  color: var(--text-primary);
}

.quote > i > b {
  color: var(--accent)
}

.theme-btn {
  position: fixed !important;
  right: 14px;
  bottom: 14px;
  border-radius: 50% !important;
  padding: 14px !important;
  min-width: 0 !important;
  z-index: 100;
}

.nav-link {
  color: var(--text-secondary);
  text-decoration: none;
  transition: var(--transition-dur);
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  letter-spacing: 1px;
}

.nav-link:hover {
  cursor: pointer;
  color: var(--text-secondary-light);
}

@media (max-width: 1199px) {
  html {
    font-size: 15px;
  }
}

@media (max-width: 899px) {
  html {
    font-size: 14.5px;
  }
}

@media (max-width: 599px) {
  html {
    font-size: 14px;
  }
}
